import React from 'react'
import Testimonials from '../Components/Testimonials'
import './screen.css'

const Reviews = () => {
    return(
        <div id="parent" >
            <h1 id="offerh1" style={{ color: "#219ebc", marginTop:'7%' }}>
       Testimonials
      </h1>
           <Testimonials />
        </div>
    )
}

export default Reviews