import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import './load.css'
const Loader2=()=>{
    return(
        <div>




<div class="loader">
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
</div>

        </div>
    )
}
export default Loader2 ;